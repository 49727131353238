export enum TimeSlot {
  Indiferent = 1,
  Mornings = 2,
  Afternoon = 3
}

export enum TimeSlotSpanish {
  Indiferente = 1,
  Mañanas = 2,
  Tardes = 3
}

export enum TimeSlotSpanishValues {
  Indiferent = 'I',
  Mornings = 'M',
  Afternoon = 'T',
}
