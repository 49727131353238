import { FormControl, Validators } from '@angular/forms';
import { TimeSlotSpanish } from '@app/shared/enums';
import { Languaje } from '@app/shared/enums/languajes';
import { StringValidators } from '@howdeniberia/core-front';
import { SexSpanish } from './../../../../shared/enums/sex';
import { EditRequestViewModel } from './edit-request-view-model';
import { RequestViewModel } from './request-view-model';

export class EditManualRequestViewModel extends EditRequestViewModel {
  constructor() {
    super();
    this.extendForm();
  }

  /* #region  Form Field Names */
  public readonly C_COMPANIES = 'companies';
  public readonly C_MAILUSER = 'mailUser';
  public readonly C_SUPERVISORMAIL = 'supervisor';
  public readonly C_MAILBO = 'mailBO';
  public readonly C_OFFICECODE = 'officeCode';
  public readonly C_OFFICENAME = 'officeName';
  public readonly C_CAPITALS = 'capitals';
  /* #endregion */

  private extendForm(): void {
    this.form.addControl(this.C_COMPANIES, new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]));
    this.form.addControl(this.C_MAILUSER, new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]));
    this.form.addControl(this.C_SUPERVISORMAIL, new FormControl('', [Validators.required, StringValidators.noSpacesEndStart, Validators.email]));
    this.form.addControl(this.C_MAILBO, new FormControl('', [Validators.required, StringValidators.noSpacesEndStart, Validators.email]));
    this.form.addControl(this.C_OBSERVATIONS, new FormControl('', [StringValidators.noSpacesEndStart]));
    this.form.addControl(this.C_CAPITALS, new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]));
    this.form.addControl(this.C_OFFICECODE, new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]));
    this.form.addControl(this.C_OFFICENAME, new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]));
    this.form.removeControl(this.C_REQUESTID);
  }

  public loadFromModel(data: RequestViewModel): void {
    this.proyectId = data.proyectId ?? 0;
    this.clientId = data.clientId ?? 0;
    this.name = data.name ?? '';
    this.surname1 = data.surname1 ?? '';
    this.surname2 = data.surname2 ?? '';
    this.nif = data.nif ?? '';
    this.birth = data.birth ? new Date(data.birth) : new Date();
    this.landLine = data.landLine;
    this.mobilePhone = data.mobilePhone;
    this.supervisor = data.supervisor;
    this.mailUser = data.mailUser;
    this.mailBO = data.mailBO;
    this.companies = data.companies;
    this.sex = Number(data.sex) ? SexSpanish[Number(data.sex)] : SexSpanish[SexSpanish.Desconocido];
    this.language = Languaje[Languaje.Castellano];
    this.timeSlot = TimeSlotSpanish[TimeSlotSpanish.Indiferente];
    this.capitals = data.capitals;
    this.officeCode = data.officeCode;
    this.officeName = data.officeName;
  }

  /* #region  Properties */

  public get companies(): string {
    return this.form.get(this.C_COMPANIES)?.value;
  }

  public set companies(value: string) {
    this.form.get(this.C_COMPANIES)?.setValue(value);
  }

  public get mailUser(): string {
    return this.form.get(this.C_MAILUSER)?.value;
  }

  public set mailUser(value: string) {
    this.form.get(this.C_MAILUSER)?.setValue(value);
  }

  public get supervisor(): string {
    return this.form.get(this.C_SUPERVISORMAIL)?.value;
  }

  public set supervisor(value: string) {
    this.form.get(this.C_SUPERVISORMAIL)?.setValue(value);
  }

  public get mailBO(): string {
    return this.form.get(this.C_MAILBO)?.value;
  }

  public set mailBO(value: string) {
    this.form.get(this.C_MAILBO)?.setValue(value);
  }

  public get officeName(): string {
    return this.form.get(this.C_PROYECTID)?.value;
  }

  public set officeName(value: string) {
    this.form.get(this.C_OFFICENAME)?.setValue(value);
  }

  public get officeCode(): string {
    return this.form.get(this.C_OFFICECODE)?.value;
  }

  public set officeCode(value: string) {
    this.form.get(this.C_OFFICECODE)?.setValue(value);
  }

  public get capitals(): string {
    return this.form.get(this.C_CAPITALS)?.value;
  }

  public set capitals(value: string) {
    this.form.get(this.C_CAPITALS)?.setValue(value);
  }
  /* #endregion */
}
