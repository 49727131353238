import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableBaseComponent } from '@app/shared/components/table-base/table-base.component';
import { REQUEST_EVENTS_LIST_COLUMNS } from '@app/shared/constants';
import { EventType } from '@app/shared/constants/event-type';
import { ResultsCodeSubCode, TeleheathResponseCodes } from '@app/shared/enums';
import { Direction, DirectionValue } from '@app/shared/enums/direction';
import { IRequestEventDto } from '@app/shared/models/api/teladoc/request-event-dto';
import { HowdenLoaderService } from '@howdeniberia/core-front';

@Component({
  selector: 'howden-request-events-list',
  templateUrl: './request-events-list.component.html',
  styleUrls: ['./request-events-list.component.scss']
})
export class RequestEventsListComponent extends TableBaseComponent implements OnInit {
  override dataSource!: MatTableDataSource<IRequestEventDto>;

  @Input() set data(value: Array<IRequestEventDto>) {
    this._data = value;
  }

  get data(): Array<IRequestEventDto> {
    return [];
  }

  override colsView = REQUEST_EVENTS_LIST_COLUMNS;

  constructor(protected override loaderSrv: HowdenLoaderService) {
    super(loaderSrv);
  }

  public direction(row: IRequestEventDto): string {
    return `${row.direction} ${row.direction === Direction.HOWDEN_TO_TELADOC
      ? DirectionValue.HOWDEN_TO_TELADOC
      : DirectionValue.TELADOC_TO_HOWDEN}`;
  }

  public eventType(row: IRequestEventDto): string {
    return `${row.direction === Direction.HOWDEN_TO_TELADOC ? EventType.REQUEST : EventType.RESPONSE}`;
  }

  public state(row: IRequestEventDto): string {
    if (row.direction === Direction.HOWDEN_TO_TELADOC) {
      const index = typeof row.responseCode === 'number' ? row.responseCode : -100;
      const res = index > -100 ? TeleheathResponseCodes[index] : '';
      return res;
    }

    const index = typeof row.resultCode === 'number' ? row.resultCode : -100;
    const res = index > -100 ? ResultsCodeSubCode[index].toString() : '';
    return res;
  }
}
