export enum ResultsCodeSubCode {
  Registrado = -1,
  Desconocido = 0,
  Cliente_Prefiere_Otro_Momento = 11,
  Cliente_No_Responde = 12,
  Cuestionario_Realizado = 2,
  Cliente_No_Localizable = 31,
  Teléfono_Inválido = 32,
  Cliente_Desconoce_Seguro = 41,
  Cliente_No_Permite_Grabación = 42,
  Cliente_No_Quiere_Seguro = 43,
  Cliente_Precisa_Contactar_Agente = 44,
  Petición_Duplicada = 45,
  Tramitado_Con_Compañía = 46,
  LOPD_Aceptada = 15,
  LOPD_En_Curso = 16,
  LOPD_Incidencia = 17,
  LOPD_No_Localizado = 18,
}
