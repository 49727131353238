import { ActivatedRoute, Params } from '@angular/router';
import { IHowdenParam } from '../models/howden-params';

export abstract class HowdenControllerWithParams {
  private queryParams: Params | undefined;
  private uriParams: Params | undefined;
  params: Array<IHowdenParam> = [];

  constructor(protected activatedRoute: ActivatedRoute) {
    this.loadParams();
  }

  private loadParams(): void {
    this.loadQueryParams();
    this.loadUriParams();
  }

  private loadQueryParams(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    Object.entries(this.queryParams).find(([key, value]) => {
      this.params.push({ name: key, value: value } as IHowdenParam);
    });
  }

  private loadUriParams(): void {
    this.uriParams = this.activatedRoute.snapshot.params;
    Object.entries(this.uriParams).find(([key, value]) => {
      this.params.push({ name: key, value: value } as IHowdenParam);
    });
  }

  public GetParamValueByName(name: string): string | number | undefined {
    return HowdenControllerWithParams.GetParamFromParamsValueByName(name, this.params);
  }

  public static GetParamFromParamsValueByName(name: string, params: Array<IHowdenParam>): string | number | undefined {
    const valStr = params.find(x => x.name.toLowerCase() === name.toLowerCase())?.value;
    if (!valStr) {
      return undefined;
    }
    try {
      const valNumber: number = +valStr;
      return valNumber;
    } catch (error) {
      return valStr;
    }
  }
}
