<h1 mat-dialog-title>Datos del cliente</h1>
<div mat-dialog-content class="flex f-fd--column f-gap--20px f-jc--start">
  @if (!model.isReactivation) {
    <span>Si no se realiza ningún cambio, no se podrá aceptar!</span>
  }
  <form [formGroup]="model.form">
    <div class="flex f-fd--column f-gap--20px f-jc--start">
      <div class="flex f-gap--10px f-ai--start f-ac--start">
        Datos generales
      </div>

      <div class="flex f-gap--10px f-ai--start f-ac--start margin-bottom-30">
        <!-- #region Name -->
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <mat-hint>No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="nombre" [formControlName]="model.C_NAME" />
          @if (model.form.get(model.C_NAME)?.hasError('required')) {
            <mat-error>Obligatorio</mat-error>
          }
          @if (model.form.get(model.C_NAME)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_NAME)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>
        <!-- #endregion -->

        <!-- #region Surname 1 -->
        <mat-form-field>
          <mat-label>Apellido1</mat-label>
          <mat-hint>No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="apellido 1" [formControlName]="model.C_SURNAME1" />
          @if (model.form.get(model.C_SURNAME1)?.hasError('required')) {
            <mat-error>Obligatorio</mat-error>
          }
          @if (model.form.get(model.C_SURNAME1)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_SURNAME1)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>
        <!-- #endregion -->

        <!-- #region Surname 2 -->
        <mat-form-field>
          <mat-label>Apellido2</mat-label>
          <mat-hint>No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="apellido 2" [formControlName]="model.C_SURNAME2" />
          @if (model.form.get(model.C_SURNAME2)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_SURNAME2)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>
        <!-- #endregion -->

        <!-- #region NIF -->
        <mat-form-field>
          <mat-label>NIF</mat-label>
          <mat-hint>No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="NIF" [formControlName]="model.C_NIF" />
          @if (model.form.get(model.C_NIF)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>
        <!-- #endregion -->
      </div>

      <div class="flex f-gap--10px f-ai--start f-ac--start">
        Teléfonos
      </div>

      <div class="flex f-gap--10px f-ai--start f-ac--start">
        <!-- #region Fijo -->
        <mat-form-field>
          <mat-label>Fijo</mat-label>
          <input matInput placeholder="tlf fijo" [formControlName]="model.C_LANDLINE" />
        </mat-form-field>
        <!-- #endregion -->

        <!-- #region Móvil -->
        <mat-form-field>
          <mat-label>Móvil</mat-label>
          <input matInput placeholder="tlf móvil" [formControlName]="model.C_MOBILE" />
        </mat-form-field>
        <!-- #endregion -->
      </div>

      @if (!numbersOK) {
        <mat-error> Uno de los dos debe cubrirse </mat-error>
      }

      <div class="flex f-gap--10px f-ai--start f-ac--start">
        Información Secundaria
      </div>

      <div class="flex f-fd--column f-gap--20px f-jc--start">
        <div class="flex f-gap--10px f-ai--start f-ac--start">
          <!-- #region Fecha Nac -->
          <mat-form-field>
            <mat-label>Fecha Nacimiento</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [howdenControlError]="fechaError"
              placeholder="nacimiento"
              [formControlName]="model.C_BRITH"
            />
            @if (model.form.get(model.C_BRITH)?.hasError('required')) {
              <mat-error>Obligatorio</mat-error>
            }
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error #fechaError></mat-error>
          </mat-form-field>
          <!-- #endregion -->

          <!-- #region Idioma -->
          <mat-form-field>
            <mat-label>Idioma</mat-label>
            <input
              matInput
              placeholder="idioma"
              [formControlName]="model.C_LANGUAGE"
              (click)="selectLanguage()"
            />
            @if (model.form.get(model.C_LANGUAGE)?.hasError('required')) {
              <mat-error>Obligatorio</mat-error>
            }
          </mat-form-field>
          <!-- #endregion -->
        </div>

        <div class="flex f-gap--10px f-ai--start f-ac--start">
          <!-- #region Horario -->
          <mat-form-field>
            <mat-label>Horario</mat-label>
            <input
              matInput
              placeholder="horario"
              [formControlName]="model.C_TIMESLOT"
              (click)="selectTimeSlot()"
            />
            @if (model.form.get(model.C_TIMESLOT)?.hasError('required')) {
              <mat-error>Obligatorio</mat-error>
            }
          </mat-form-field>
          <!-- #endregion -->

          <!-- #region Sex -->
          <mat-form-field>
            <mat-label>Sexo</mat-label>
            <input
              matInput
              placeholder="sexo"
              [formControlName]="model.C_SEX"
              (click)="selectSex()"
            />
            @if (model.form.get(model.C_SEX)?.hasError('required')) {
              <mat-error>Obligatorio</mat-error>
            }
          </mat-form-field>
          <!-- #endregion -->
        </div>

        <div class="flex f-gap--10px f-ai--start f-ac--start">
          <!-- #region Observaciones -->
          <mat-form-field>
            <mat-label>Observaciones</mat-label>
            <input matInput placeholder="observaciones" [formControlName]="model.C_OBSERVATIONS" />
            @if (model.form.get(model.C_OBSERVATIONS)?.hasError('noSpacesEndStart')) {
              <mat-error>Sin espacios pls</mat-error>
            }
          </mat-form-field>
          <!-- #endregion -->
        </div>
      </div>
    </div>

    <div mat-dialog-actions [align]="'end'">
      <button mat-stroked-button color="warn" (click)="close(false)">
        <mat-icon class="red">cancel</mat-icon>
        Cancelar
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="(!model.formChanged && !model.isReactivation) || !model.form.valid"
        (click)="close(true)"
      >
        <mat-icon class="blue">task_alt</mat-icon>
        Aceptar
      </button>
      <button mat-raised-button (click)="clean()">
        <mat-icon>cleaning_services</mat-icon>
        <span>Limpiar</span>
      </button>
    </div>
  </form>
  <span>En cuanto se acepte, se enviará la actualización al servicio</span>
</div>
