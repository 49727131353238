import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenAlertsModule,
  HowdenAskForChecksModule,
  HowdenAskForSelectModule,
  HowdenCheckboxListModule,
  HowdenLabelValueModule,
  HowdenRadiobuttonListModule,
  HowdenSharedModule
} from '@howdeniberia/core-front';
import { ManualRequestEditDialogComponent } from './components/manual-request-edit-dialog/manual-request-edit-dialog.component';
import { ReadableInfoDialogComponent } from './components/readable-info-dialog/readable-info-dialog.component';
import {
  RequestClientDataEditDialogComponent
} from './components/request-client-data-edit-dialog/request-client-data-edit-dialog.component';
import { RequestEventsListComponent } from './components/request-events-list/request-events-list.component';
import { RequestsFilterFormDialogComponent } from './components/requests-filter-form-dialog/requests-filter-form-dialog.component';
import { RequestsListComponent } from './components/requests-list/requests-list.component';
import { TeladocEventsPageComponent } from './pages/teladoc-events-page/teladoc-events-page.component';
import { TeladocEventsRoutingModule } from './teladoc-events-routing.module';

@NgModule({
  declarations: [
    TeladocEventsPageComponent,
    RequestsListComponent,
    RequestEventsListComponent,
    RequestClientDataEditDialogComponent,
    ManualRequestEditDialogComponent,
    RequestsFilterFormDialogComponent,
    ReadableInfoDialogComponent
  ],
  imports: [
    CommonModule,
    TeladocEventsRoutingModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    HowdenRadiobuttonListModule,
    HowdenCheckboxListModule,
    HowdenLabelValueModule,
    HowdenAlertsModule,
    HowdenSharedModule,
    HowdenAskForSelectModule,
    HowdenAskForChecksModule
  ],
  exports: [ManualRequestEditDialogComponent]
})
export class TeladocEventsModule {
}
