import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HowdenLoaderService, IHowdenYesNoData } from '@howdeniberia/core-front';

@Component({
  selector: 'howden-table-base',
  templateUrl: './table-base.component.html',
  styleUrls: ['./table-base.component.scss']
})
export class TableBaseComponent implements OnInit, AfterViewInit {
  elementLoading = -1;
  loading = false;

  get defaultAskYesNoOptions(): IHowdenYesNoData {
    return {
      title: 'Atención',
      icon: 'warning',
      disableClose: true,
      widthPixels: 550
    } as IHowdenYesNoData;
  }

  get noResults(): boolean {
    return !this.loading && (!this.dataSource || this.dataSource.data.length === 0);
  }

  get showTable(): boolean {
    return !this.loading && !this.noResults;
  }

  get showPaginator(): boolean {
    return !!this.paginator && this.paginate > 0
      && this.dataSource && this.dataSource.data
      && this.dataSource.data.length > this.paginate;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // @Input() data?: any[] | null;
  _data?: unknown[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() dataSecondary?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandedElement: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() dataSource: MatTableDataSource<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() dataSourceSecondary: MatTableDataSource<any>;

  @Input() paginate = 0;
  @Input() colsView!: string[];
  @Input() colsDetailView!: string[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(protected readonly loaderSrv: HowdenLoaderService) {
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // DOT NOT REMOVE, needed for inihiterance
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit(): void {
    // DOT NOT REMOVE, needed for inihiterance
  }

  callLoading(): void {
    this.loaderSrv.showSpinner();
    this.loading = true;
  }

  endLoading(): void {
    this.loaderSrv.hideSpinner();
    this.loading = false;
  }

  refresh() {
    this.dataSource = new MatTableDataSource<unknown>(this.dataSource?.data);
    this.refreshPaginator();
    this.refreshSort(true);
  }

  loadData(useData?: Array<unknown>, paginateEach: number | null = null, useSort = true): void {
    this.callLoading();
    this._data = !useData ? null : useData;
    this.dataSource = new MatTableDataSource<unknown>(this._data ? this._data : []);
    this.paginate = !paginateEach && !this.paginate ? 0 :
      !paginateEach && this.paginate ? this.paginate : paginateEach ? paginateEach : 0;
    this.refreshPaginator();
    this.refreshSort(useSort);
    this.endLoading();
  }

  refreshPaginator(): void {
    if (this.showPaginator) {
      this.paginator.pageSize = this.paginate;
      this.dataSource.paginator = this.paginator;
    } else {
      this.dataSource.paginator = null;
    }
  }

  refreshSort(useSort = true): void {
    if (this.sort && this.dataSource) {
      this.dataSource.sort = useSort ? this.sort : null;
    }
  }

  unLoadData() {
    this.callLoading();
    this.dataSource = new MatTableDataSource<unknown>([]);
    this.endLoading();
  }

  applyFilter(event: Event): void {
    if (!this.dataSource) {
      return;
    }
    // eslint-disable-next-line no-extra-parens
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action(row: unknown): void {
    // to override
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  eventClick(row: unknown): void {
    // to override
  }
}
