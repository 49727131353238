import { DatePipe } from '@angular/common';
import { ParamMap } from '@angular/router';
import { IRegionsDto } from '@app/features/teladoc-events/models/ui/regions-dto';
import { TimeSlot, TimeSlotSpanish, TimeSlotSpanishValues } from '@app/shared/enums';
import { RequestFiltersFastOptions, RequestFiltersStatusTypes } from '@app/shared/enums/common-enum';
import { Languaje, LanguajeValues } from '@app/shared/enums/languajes';
import { IRequestFrontFilterDto } from '@app/shared/models/api/teladoc/request-front-filter-dto';
import { IRequestNewDto } from '@app/shared/models/api/teladoc/request-new-dto';
import { IRequestShortDto } from '@app/shared/models/api/teladoc/request-short-dto';
import { RequestFilterFormViewModel } from '@features/teladoc-events/models/ui/request-filter-form-view-model';
import { IHowdenItem } from '@howdeniberia/core-front';
import { IRequestFrontFilterAppDto } from '@sharedModels/api/teladoc/request-front-filter-app-dto';
import { EditManualRequestViewModel } from '../models/ui/edit-manual-request-view-model';
import { EditRequestViewModel } from '../models/ui/edit-request-view-model';
import { Sex, SexSpanish, SexSpanishVaLues } from './../../../shared/enums/sex';
import { RequestViewModel } from './../models/ui/request-view-model';

export class RequestMapper {
  /* #region RequestViewModel */
  public queryParamsToRequestViewModel(params: ParamMap): RequestViewModel {
    const val = new RequestViewModel();
    val.proyectId = Number(params.get('p') ?? 0);
    val.clientId = Number(params.get('c') ?? 0);
    val.companies = params.get('cs') ?? '';
    val.name = params.get('n') ?? '';
    val.surname1 = params.get('s1') ?? '';
    val.surname2 = params.get('s2') ?? '';
    val.nif = params.get('nf') ?? '';
    val.birth = new Date(params.get('b') ?? '');
    val.landLine = this.cleanTelephone(params.get('l') ?? '');
    val.mobilePhone = this.cleanTelephone(params.get('tb') ?? '');
    val.sex = params.get('s') ?? '';
    val.supervisor = params.get('ms') ?? '';
    val.mailUser = params.get('mm') ?? '';
    val.mailBO = params.get('mb') ?? '';
    val.capitals = params.get('ca') ?? '';
    val.officeCode = params.get('oc') ?? '';
    val.officeName = params.get('on') ?? '';
    val.advice = params.get('av') === '1' ? true : false;
    return val;
  }

  private cleanTelephone(value: string): string {
    const res = value.split(' ').join('').split('-').join('');
    return res;
  }

  public editManualRequestViewModelToRequestViewModel(data: EditManualRequestViewModel): RequestViewModel {
    const result: RequestViewModel = new RequestViewModel();
    result.formAsJSON = data.formVal;
    result.proyectId = data.proyectId;
    result.clientId = data.clientId;
    result.birth = data.birth;
    result.companies = data.companies;
    result.landLine = this.cleanTelephone(data.landLine);
    result.mobilePhone = this.cleanTelephone(data.mobilePhone);
    result.mailBO = data.mailBO;
    result.name = data.name;
    result.nif = data.nif;
    result.observations = data.observations;
    result.sex = data.sex;
    result.supervisor = data.supervisor;
    result.surname1 = data.surname1;
    result.surname2 = data.surname2;
    result.timeSlot = data.timeSlot;
    result.mailUser = data.mailUser;
    result.language = data.language;
    result.officeCode = data.officeCode;
    result.officeName = data.officeName;
    result.capitals = data.capitals;
    return result;
  }

  public requestViewModelToIRequestShortDto(data: RequestViewModel): IRequestShortDto {
    const obj: IRequestShortDto = <IRequestShortDto>JSON.parse(data.formAsJSON);
    return obj;
  }

  public requestViewModelToRequestNewDto(source: RequestViewModel): IRequestNewDto {
    const obj: IRequestNewDto = <IRequestNewDto>JSON.parse(source.formAsJSON);
    obj.mobilePhone = this.cleanTelephone(obj.mobilePhone);
    obj.landLine = this.cleanTelephone(obj.landLine);
    obj.sex = this.getSexEnumValueFromString(source.sex);
    obj.timeSlot = this.getTimeSlotEnumValueFromString(source.timeSlot);
    obj.language = this.getLanguajeEnumValueFromString(source.language);
    return obj;
  }
  /* #endregion */

  /* #region IRequestShortDto, EditRequestViewModel */
  public requestShortDtoToEditRequestViewModel(data: IRequestShortDto): EditRequestViewModel {
    const result: EditRequestViewModel = new EditRequestViewModel();
    // result.formAsJSON = data.formVal;
    result.proyectId = data.proyectId;
    result.clientId = data.clientId;
    result.birth = data.birth ? new Date(data.birth) : new Date();
    result.landLine = this.cleanTelephone(data.landLine);
    result.mobilePhone = this.cleanTelephone(data.mobilePhone);
    result.name = data.name;
    result.surname1 = data.surname1;
    result.surname2 = data.surname2;
    result.nif = data.nif;
    result.observations = data.observations;
    result.sex = data.sex;
    result.language = this.getLanguajeEnumValueFromString(data.language);
    result.sex = this.getSexEnumValueFromString(data.sex);
    result.timeSlot = this.getTimeSlotEnumValueFromString(data.timeSlot);
    // result.companies = data.companies;
    // result.mailBO = data.mailBO;
    // result.supervisor = data.supervisor;
    // result.mailUser = data.mailUser;
    return result;
  }

  public editRequestViewModelToIRequestShortDto(data: EditRequestViewModel): IRequestShortDto {
    const obj: IRequestShortDto = <IRequestShortDto>JSON.parse(data.formVal);
    obj.mobilePhone = this.cleanTelephone(obj.mobilePhone);
    obj.landLine = this.cleanTelephone(obj.landLine);
    obj.sex = this.getSexEnumValueFromString(data.sex);
    obj.timeSlot = this.getTimeSlotEnumValueFromString(data.timeSlot);
    obj.language = this.getLanguajeEnumValueFromString(data.language);
    return obj;
  }
  /* #endregion */

  /* #region Enum Sex */
  public getSexEnumValueFromString(value: string): string {
    switch (value) {
      case Sex[Sex.Man]:
      case SexSpanishVaLues.Hombre:
      case SexSpanish[SexSpanish.Hombre]:
        return SexSpanishVaLues.Hombre;
      case Sex[Sex.Woman]:
      case SexSpanishVaLues.Mujer:
      case SexSpanish[SexSpanish.Mujer]:
        return SexSpanishVaLues.Mujer;
      default:
        return SexSpanishVaLues.Desconocido;
    }
  }

  public getSexEnumTextFromString(value: string): string {
    switch (value) {
      case Sex[Sex.Man]:
      case SexSpanishVaLues.Hombre:
      case SexSpanish[SexSpanish.Hombre]:
        return SexSpanish[SexSpanish.Hombre];
      case Sex[Sex.Woman]:
      case SexSpanishVaLues.Mujer:
      case SexSpanish[SexSpanish.Mujer]:
        return SexSpanish[SexSpanish.Mujer];
      default:
        return SexSpanish[SexSpanish.Desconocido];
    }
  }
  /* #endregion */

  /* #region Tim Slot Enum */
  public getTimeSlotEnumValueFromString(value: string): string {
    switch (value) {
      case TimeSlot[TimeSlot.Afternoon]:
      case TimeSlotSpanishValues.Afternoon:
      case TimeSlotSpanish[TimeSlotSpanish.Tardes]:
        return TimeSlotSpanishValues.Afternoon;
      case TimeSlot[TimeSlot.Indiferent]:
      case TimeSlotSpanishValues.Indiferent:
      case TimeSlotSpanish[TimeSlotSpanish.Indiferente]:
        return TimeSlotSpanishValues.Indiferent;
      case TimeSlot[TimeSlot.Mornings]:
      case TimeSlotSpanishValues.Mornings:
      case TimeSlotSpanish[TimeSlotSpanish.Mañanas]:
        return TimeSlotSpanishValues.Mornings;
      default:
        return TimeSlotSpanishValues.Indiferent;
    }
  }

  public getTimeSlotEnumTextFromString(value: string): string {
    switch (value) {
      case TimeSlot[TimeSlot.Afternoon]:
      case TimeSlotSpanishValues.Afternoon:
      case TimeSlotSpanish[TimeSlotSpanish.Tardes]:
        return TimeSlotSpanish[TimeSlotSpanish.Tardes];
      case TimeSlot[TimeSlot.Indiferent]:
      case TimeSlotSpanishValues.Indiferent:
      case TimeSlotSpanish[TimeSlotSpanish.Indiferente]:
        return TimeSlotSpanish[TimeSlotSpanish.Indiferente];
      case TimeSlot[TimeSlot.Mornings]:
      case TimeSlotSpanishValues.Mornings:
      case TimeSlotSpanish[TimeSlotSpanish.Mañanas]:
        return TimeSlotSpanish[TimeSlotSpanish.Mañanas];
      default:
        return TimeSlotSpanishValues.Indiferent;
    }
  }
  /* #endregion */

  /* #region Languaje Enum */
  public getLanguajeEnumValueFromString(value: string): string {
    switch (value) {
      case Languaje[Languaje.Castellano]:
      case LanguajeValues.Castellano:
        return LanguajeValues.Castellano;
      case Languaje[Languaje.Aleman]:
      case LanguajeValues.Aleman:
        return LanguajeValues.Aleman;
      case Languaje[Languaje.Catalan]:
      case LanguajeValues.Catalan:
        return LanguajeValues.Catalan;
      case Languaje[Languaje.Chino]:
      case LanguajeValues.Chino:
        return LanguajeValues.Chino;
      case Languaje[Languaje.Frances]:
      case LanguajeValues.Frances:
        return LanguajeValues.Frances;
      case Languaje[Languaje.Ingles]:
      case LanguajeValues.Ingles:
        return LanguajeValues.Ingles;
      case Languaje[Languaje.Italiano]:
      case LanguajeValues.Italiano:
        return LanguajeValues.Italiano;
      case Languaje[Languaje.Portugues]:
      case LanguajeValues.Portugues:
        return LanguajeValues.Portugues;
      default:
        return LanguajeValues.Castellano;
    }
  }

  public getLanguajeEnumTextFromString(value: string): string {
    switch (value) {
      case Languaje[Languaje.Castellano]:
      case LanguajeValues.Castellano:
        return Languaje[Languaje.Castellano];
      case Languaje[Languaje.Aleman]:
      case LanguajeValues.Aleman:
        return Languaje[Languaje.Aleman];
      case Languaje[Languaje.Catalan]:
      case LanguajeValues.Catalan:
        return Languaje[Languaje.Catalan];
      case Languaje[Languaje.Chino]:
      case LanguajeValues.Chino:
        return Languaje[Languaje.Chino];
      case Languaje[Languaje.Frances]:
      case LanguajeValues.Frances:
        return Languaje[Languaje.Frances];
      case Languaje[Languaje.Ingles]:
      case LanguajeValues.Ingles:
        return Languaje[Languaje.Ingles];
      case Languaje[Languaje.Italiano]:
      case LanguajeValues.Italiano:
        return Languaje[Languaje.Italiano];
      case Languaje[Languaje.Portugues]:
      case LanguajeValues.Portugues:
        return Languaje[Languaje.Portugues];
      default:
        return Languaje[Languaje.Castellano];
    }
  }
  /* #endregion */

  /* #region Contact Numbers */
  public validateContactNumbers(
    landLine: string | null,
    mobilePhone: string | null
  ): boolean {
    landLine = landLine
      ? landLine
          ?.split(' ')
          .join('')
          .split('-')
          .join('')
          .split('+')
          .join('')
          .split('(')
          .join('')
          .split(')')
          .join('')
      : null;
    const landOK = Number(landLine) ? true : false;
    mobilePhone = mobilePhone
      ? mobilePhone
          ?.split(' ')
          .join('')
          .split('-')
          .join('')
          .split('+')
          .join('')
          .split('(')
          .join('')
          .split(')')
          .join('')
      : null;
    const mobOK = Number(mobilePhone) ? true : false;
    return landOK || mobOK;
  }
  /* #endregion */

  /* #region IRequestFrontFilterAppDto, RequestFilterFormViewModel */
  public iRequestFrontFilterAppDtoToRequestFilterFormViewModel(
    data: IRequestFrontFilterAppDto | undefined,
    regions: Array<IRegionsDto> | undefined
  ): RequestFilterFormViewModel {
    const res = new RequestFilterFormViewModel();
    // load Offices
    const offices: Array<IHowdenItem> = [];
    if (regions && regions.length > 0) {
      regions.forEach((reg) => {
        reg.officeLocations.forEach((office) => {
          const newOffice = {
            key: office.officeCode,
            description: `${office.officeName} (${office.officeCode})`
          } as IHowdenItem;
          offices.push(newOffice);
        });
      });
      res.officesOptions = offices;
    }
    if (data) {
      /* #region Dates */
      if (!!data.dtRegisterDateFrom || !!data.dtRegisterDateUntil) {
        res.dtFast = null;
        if (data.dtRegisterDateFrom !== '') {
          res.dtRegisterDateFrom = data.dtRegisterDateFrom;
        }
        if (data.dtRegisterDateUntil !== '') {
          res.dtRegisterDateUntil = data.dtRegisterDateUntil;
        }
      } else {
        if (data.dtFast) {
          res.dtFast = data.dtFast;
        }
      }
      /* #endregion */
      // Options
      res.stTYPES = data.stTypes;
      // Offices
      res.officeCodes = data.officeCodes;
      /* #endregion */
    }

    res.setInitialFormValue();
    return res;
  }

  public requestFilterFormViewModelToIRequestFrontFilterAppDto(
    data: RequestFilterFormViewModel
  ): IRequestFrontFilterAppDto {
    const obj: IRequestFrontFilterAppDto = <IRequestFrontFilterAppDto>(
      JSON.parse(data.formVal)
    );
    if (obj.dtFast) {
      obj.dtRegisterDateFrom = null;
      obj.dtRegisterDateUntil = null;
    } else if (obj.dtRegisterDateFrom || obj.dtRegisterDateUntil) {
      obj.dtFast = null;
    }
    return obj;
  }

  public iRequestFrontFilterAppDtoToIRequestFrontFilterDto(
    data: IRequestFrontFilterAppDto | undefined
  ): IRequestFrontFilterDto {
    const obj: IRequestFrontFilterDto = {} as IRequestFrontFilterDto;
    if (!data) {
      return obj;
    }
    // Dates
    if (data.dtRegisterDateFrom || data.dtRegisterDateUntil) {
      obj.dtRegisterDateFrom = data.dtRegisterDateFrom
        ? data.dtRegisterDateFrom
        : null;
      obj.dtRegisterDateUntil = data.dtRegisterDateUntil
        ? data.dtRegisterDateUntil
        : null;
    } else if (data.dtFast && data.dtFast !== RequestFiltersFastOptions.Ninguno) {
      const res = this.calculateDatesWithFastOption(data.dtFast);
      obj.dtRegisterDateFrom = res[0];
      obj.dtRegisterDateUntil = res[1];
    }
    // Options
    if (data.stTypes) {
      obj.stDeleted = data.stTypes.findIndex((x) => x === RequestFiltersStatusTypes.Borradas) > -1
          ? true
          : null;
      obj.stFinished = data.stTypes.findIndex((x) => x === RequestFiltersStatusTypes.Completadas) > -1
          ? true
          : null;
      obj.stInProgress = data.stTypes.findIndex((x) => x === RequestFiltersStatusTypes.En_Circuito) > -1
          ? true
          : null;
      obj.stWithIncidence = data.stTypes.findIndex((x) => x === RequestFiltersStatusTypes.Con_Incidencia) > -1
          ? true
          : null;
    }
    obj.officeCodes = data.officeCodes?.length === 0 ? [] : data.officeCodes;
    return obj;
  }

  private calculateDatesWithFastOption(option: number | null): [string | null, string | null] {
    const today: Date = new Date();
    let dateFrom: Date | null = null;
    let dateUntil: Date | null = null;
    const actualTr = Math.floor(today.getMonth() / 3) + 1;
    let actualTriEnd: number | null = null;
    let actualTriInit: number | null = null;
    let year: number | null = null;
    const pipe: DatePipe = new DatePipe('es-ES');
    switch (option) {
      case RequestFiltersFastOptions.Mes_Actual:
        dateFrom = new Date(today.getFullYear(), today.getMonth(), 1);
        dateUntil = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case RequestFiltersFastOptions.Mes_Pasado:
        dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        dateUntil = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case RequestFiltersFastOptions.Trimestre_Actual:
        actualTriEnd = actualTr * 3;
        actualTriInit = actualTriEnd - 3;
        dateFrom = new Date(today.getFullYear(), actualTriInit, 1);
        dateUntil = new Date(today.getFullYear(), actualTriEnd, 0);
        break;
      case RequestFiltersFastOptions.Trimestre_Pasado:
        year = today.getFullYear() + (actualTr === 1 ? 1 : 0);
        actualTriEnd = (actualTr === 1 ? 4 : actualTr - 1) * 3;
        actualTriInit = actualTriEnd - 3;
        dateFrom = new Date(year, actualTriInit, 1);
        dateUntil = new Date(year, actualTriEnd, 0);
        break;
    }
    const from = pipe.transform(dateFrom, 'yyyy-MM-ddT00:00:00.000');
    const until = pipe.transform(dateUntil, 'yyyy-MM-ddT23:59:59.999');
    return [from ? from : null, until ? until : null];
  }
  /* #endregion */
}
