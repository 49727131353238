import { EnviromentZone, HowdenLoginBehaviour, HowdenLoginKind, IHowdenEnvironment, LogLevel } from '@howdeniberia/core-front';

export const environment = {
  zone: EnviromentZone.DEVHOWDENGROUP,
  appIdSecurity: 3,
  emailError: 'desarrollo@howdengroup.com',
  loginBehaviour: HowdenLoginBehaviour.REDIRECT,
  loginKind: HowdenLoginKind.INTERNAL,
  howdenLoginUrl: 'https://how-login.app-dev.howdeniberia.com',
  logLevel: LogLevel.INFO
} as IHowdenEnvironment;
