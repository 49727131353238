import { Injectable } from '@angular/core';
import { IAppData } from '@app/core/models/app-data';
import packageJson from 'package.json';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {
  appKey = packageJson.name;
  storage!: Storage;

  constructor() {
    this.storage = localStorage;
  }

  saveAppData(data: IAppData): void {
    const dataStr = JSON.stringify(data);
    this.storage.setItem(this.appKey, dataStr);
  }

  readAppdata(): IAppData {
    const val = this.storage.getItem(this.appKey);
    if (!val) {
      return {} as IAppData;
    }
    const obj = JSON.parse(val);
    return <IAppData>obj;
  }
}
