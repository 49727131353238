<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="direction">
    <th mat-header-cell *matHeaderCellDef>Comunicación</th>
    <td mat-cell *matCellDef="let detail">{{ direction(detail) }}</td>
  </ng-container>
  <ng-container matColumnDef="dateSend">
    <th mat-header-cell *matHeaderCellDef>Fech Env.</th>
    <td mat-cell *matCellDef="let detail">
      {{ detail.dateSend | date : "yyyy/MM/dd HH:mm" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dateReceived">
    <th mat-header-cell *matHeaderCellDef>Fech Rec.</th>
    <td mat-cell *matCellDef="let detail">
      {{ detail.dateReceived | date : "yyyy/MM/dd HH:mm" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Evento</th>
    <td mat-cell *matCellDef="let detail">{{ eventType(detail) }}</td>
  </ng-container>
  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef>Estado</th>
    <td mat-cell *matCellDef="let detail">{{ state(detail) }}</td>
  </ng-container>
  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef>Msg</th>
    <td mat-cell *matCellDef="let detail">{{ detail.message }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="colsView"></tr>
  <tr mat-row *matRowDef="let row; columns: colsView"></tr>
</table>

<mat-paginator
  [class.hidden]="!showPaginator"
  [showFirstLastButtons]="true"
  [pageSizeOptions]="[10, 25, 50, 100]"
  [pageSize]="paginate"
></mat-paginator>
