<form [formGroup]="model.form">
  <div class="howden-dialog-content">
    <div mat-dialog-title class="title-text">Datos del cliente</div>
    <div class="subtitle-text">petición manual para TELADOC</div>

    <mat-dialog-content>
      <div class="flex f-gap--5px margin-bottom-30">
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <mat-hint>No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="nombre" [formControlName]="model.C_NAME" />
          @if (model.form.get(model.C_NAME)?.hasError('required')) {
            <mat-error>Obligatorio</mat-error>
          }
          @if (model.form.get(model.C_NAME)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_NAME)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>

        <mat-form-field>
          <mat-label>Apellido1</mat-label>
          <mat-hint>No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="apellido 1" [formControlName]="model.C_SURNAME1" />
          @if (model.form.get(model.C_SURNAME1)?.hasError('required')) {
            <mat-error>Obligatorio</mat-error>
          }
          @if (model.form.get(model.C_SURNAME1)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_SURNAME1)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>

        <mat-form-field>
          <mat-label>Apellido2</mat-label>
          <mat-hint>No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="apellido 2" [formControlName]="model.C_SURNAME2" />
          @if (model.form.get(model.C_SURNAME2)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_SURNAME2)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>

        <mat-form-field>
          <mat-label>NIF</mat-label>
          <mat-hint>No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="NIF" [formControlName]="model.C_NIF" />
          @if (model.form.get(model.C_NIF)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>

        <mat-form-field>
          <mat-label>Fecha Nacimiento</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [howdenControlError]="fechaError"
            placeholder="nacimiento"
            [formControlName]="model.C_BRITH"
          />
          @if (model.form.get(model.C_BRITH)?.hasError('required')) {
            <mat-error>Obligatorio</mat-error>
          }
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error #fechaError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">Teléfonos</div>
      @if (!numbersOK) {
        <div class="flex f-gap--10px">
          <mat-error> Uno de los dos debe cubrirse </mat-error>
        </div>
      }

      <div class="flex f-gap--10px">
        <mat-form-field>
          <mat-label>Fijo</mat-label>
          <input matInput placeholder="tlf fijo" [formControlName]="model.C_LANDLINE" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Móvil</mat-label>
          <input matInput placeholder="tlf móvil" [formControlName]="model.C_MOBILE" />
        </mat-form-field>
      </div>

      <span>Contacto</span>
      <div class="flex f-gap--10px">
        <mat-form-field>
          <mat-label>Mail Gestor</mat-label>
          <input matInput placeholder="Mail Gestor" [formControlName]="model.C_MAILUSER" />
          @if (model.form.get(model.C_MAILUSER)?.hasError('required')) {
            <mat-error>Obligatorio</mat-error>
          }
          @if (model.form.get(model.C_MAILUSER)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios pls</mat-error>
          }
        </mat-form-field>

        <mat-form-field>
          <mat-label>Mail Supervisor</mat-label>
          <input matInput placeholder="Mail Supervisor" [formControlName]="model.C_SUPERVISORMAIL" />
          @if (model.form.get(model.C_SUPERVISORMAIL)?.hasError('required')) {
            <mat-error>Obligatorio</mat-error>
          }
          @if (model.form.get(model.C_SUPERVISORMAIL)?.hasError('email')) {
            <mat-error>mail no válido</mat-error>
          }
        </mat-form-field>

        <mat-form-field>
          <mat-label>Mail BO</mat-label>
          <input matInput placeholder="Mail BO" [formControlName]="model.C_MAILBO" />
          @if (model.form.get(model.C_MAILBO)?.hasError('required')) {
            <mat-error>Obligatorio</mat-error>
          }
          @if (model.form.get(model.C_MAILBO)?.hasError('email')) {
            <mat-error>
              mail no válido
            </mat-error>
          }
        </mat-form-field>
      </div>

      <span>Información Secundaria</span>
      <div class="flex f-fnd--column f-gap--20px f-jc--start">
        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label>Compañías</mat-label>
            <input
              matInput
              placeholder="compañías"
              [formControlName]="model.C_COMPANIES"
              (click)="selectCompanies()"
            />
            @if (model.form.get(model.C_COMPANIES)?.hasError('required')) {
              <mat-error>Obligatorio</mat-error>
            }
          </mat-form-field>

          <mat-form-field>
            <mat-label>Capitales</mat-label>
            <input matInput placeholder="capitales" [formControlName]="model.C_CAPITALS" />
            @if (model.form.get(model.C_CAPITALS)?.hasError('required')) {
              <mat-error>Obligatorio</mat-error>
            }
            @if (model.form.get(model.C_CAPITALS)?.hasError('wrongValue')) {
              <mat-error>Algún capital es incorrecto</mat-error>
            }
          </mat-form-field>

          <mat-form-field>
            <mat-label>Idioma</mat-label>
            <input
              matInput
              placeholder="idioma"
              [formControlName]="model.C_LANGUAGE"
              (click)="selectLanguage()"
            />
            @if (model.form.get(model.C_LANGUAGE)?.hasError('required')) {
              <mat-error>Obligatorio</mat-error>
            }
          </mat-form-field>

          <mat-form-field>
            <mat-label>Horario</mat-label>
            <input
              matInput
              placeholder="horario"
              [formControlName]="model.C_TIMESLOT"
              (click)="selectTimeSlot()"
            />
            @if (model.form.get(model.C_TIMESLOT)?.hasError('required')) {
              <mat-error>Obligatorio</mat-error>
            }
          </mat-form-field>
        </div>
      </div>

      <div class="flex f-fnd--column f-gap--20px f-jc--start">
        <div class="flex f-gap--10px">
          <mat-form-field>
            <mat-label>Sexo</mat-label>
            <input
              matInput
              placeholder="sexo"
              [formControlName]="model.C_SEX"
              (click)="selectSex()"
            />
            @if (model.form.get(model.C_SEX)?.hasError('required')) {
              <mat-error
                >
                Obligatorio
              </mat-error>
            }
          </mat-form-field>

          <mat-form-field>
            <mat-label>Observaciones</mat-label>
            <input matInput placeholder="observaciones" [formControlName]="model.C_OBSERVATIONS" />
            @if (model.form.get(model.C_OBSERVATIONS)?.hasError('noSpacesEndStart')) {
              <mat-error>Sin espacios pls</mat-error>
            }
          </mat-form-field>

          <mat-form-field>
            <mat-label>Ofi Codigo</mat-label>
            <input matInput placeholder="ofi. code" [formControlName]="model.C_OFFICECODE" />
            @if (model.form.get(model.C_OFFICECODE)?.hasError('noSpacesEndStart')) {
              <mat-error>Sin espacios pls</mat-error>
            }
          </mat-form-field>

          <mat-form-field>
            <mat-label>Ofi Nombre</mat-label>
            <input matInput placeholder="ofi. nombre" [formControlName]="model.C_OFFICENAME" />
            @if (model.form.get(model.C_OFFICENAME)?.hasError('noSpacesEndStart')) {
              <mat-error>Sin espacios pls</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <div class="flex f-gap--5px f-jc--end f-ai--center f-ac--center">
        <button mat-stroked-button color="warn" (click)="close(false)">
          <mat-icon class="red">cancel</mat-icon>
          Cancelar
        </button>
        <button mat-raised-button color="primary" (click)="close(true)">
          <mat-icon class="blue">task_alt</mat-icon>
          Aceptar
        </button>
        <button mat-raised-button (click)="clean()">
          <mat-icon>cleaning_services</mat-icon>
          <span>Limpiar</span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
