import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppManagementService } from '@app/core/services/app/app-management.service';
import { RequestFilterFormViewModel } from '@features/teladoc-events/models/ui/request-filter-form-view-model';
import { IRequestFormFilterDialogResult } from '@sharedModels/custom/request-form-filter-dialog-result';
import { RequestMapper } from './../../mappers/request-mapper';

@Component({
  selector: 'howden-requests-filter-form-dialog',
  templateUrl: './requests-filter-form-dialog.component.html',
  styleUrls: ['./requests-filter-form-dialog.component.scss']
})
export class RequestsFilterFormDialogComponent implements OnInit {
  model: RequestFilterFormViewModel = new RequestFilterFormViewModel();
  mapper: RequestMapper = new RequestMapper();

  constructor(
    public dialogRef: MatDialogRef<RequestsFilterFormDialogComponent>,
    private readonly appDataSrv: AppManagementService) {
  }

  ngOnInit(): void {
    this.model = this.mapper.iRequestFrontFilterAppDtoToRequestFilterFormViewModel(
      this.appDataSrv.lastRequestFilter,
      this.appDataSrv.avaliableRegions);
  }

  close(accept: boolean): void {
    const result = {
      ok: accept && this.model.anyValueSet,
      value: this.model
    } as IRequestFormFilterDialogResult;
    this.dialogRef.close(result);
  }

  clean(): void {
    this.model.resetForm();
  }
}
