import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { TelesuscripcionAccessRoles } from '@shared/models/api/teladoc/telesuscripcion-access-roles';
import { TeladocEventsPageComponent } from './pages/teladoc-events-page/teladoc-events-page.component';

// children of '' route
export const TeladocEventsRoutes = [
  {
    path: 'requests',
    component: TeladocEventsPageComponent,
    title: $localize`:@@app.route.main:Eventos Teladoc`,
    canActivate: [AuthGuard],
    data: {
      isLayout: false,
      roles: [TelesuscripcionAccessRoles.TELESUSCRIPCIONADMIN,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONGESTOR,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONBO,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CAC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CATALUÑA,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CENTROSUR,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_LEVANTE,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_NORTE
      ]
    }
  },
  {
    path: 'manual',
    component: TeladocEventsPageComponent,
    title: $localize`:@@app.route.main:Eventos Teladoc`,
    canActivate: [AuthGuard],
    data: {
      isLayout: false,
      roles: [TelesuscripcionAccessRoles.TELESUSCRIPCIONADMIN, TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC]
    }
  },
  {
    path: 'requests/client/:clientId',
    component: TeladocEventsPageComponent,
    title: $localize`:@@app.route.main:Eventos Teladoc`,
    canActivate: [AuthGuard],
    data: {
      isLayout: false,
      roles: [TelesuscripcionAccessRoles.TELESUSCRIPCIONADMIN,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONGESTOR,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONBO,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CAC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CATALUÑA,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CENTROSUR,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_LEVANTE,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_NORTE
      ]
    }
  },
  {
    path: 'requests/proyect/:proyectId',
    component: TeladocEventsPageComponent,
    title: $localize`:@@app.route.main:Eventos Teladoc`,
    canActivate: [AuthGuard],
    data: {
      isLayout: false,
      roles: [TelesuscripcionAccessRoles.TELESUSCRIPCIONADMIN,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONGESTOR,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONBO,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CAC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CATALUÑA,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CENTROSUR,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_LEVANTE,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_NORTE
      ]
    }
  },
  {
    path: 'request/:requestId',
    component: TeladocEventsPageComponent,
    title: $localize`:@@app.route.main:Eventos Teladoc`,
    canActivate: [AuthGuard],
    data: {
      isLayout: false,
      roles: [TelesuscripcionAccessRoles.TELESUSCRIPCIONADMIN,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONGESTOR,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONBO,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CAC,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CATALUÑA,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_CENTROSUR,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_LEVANTE,
      TelesuscripcionAccessRoles.TELESUSCRIPCIONDT_NORTE
      ]
    }
  }
] as Routes;
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(TeladocEventsRoutes)],
  exports: [RouterModule]
})
export class TeladocEventsRoutingModule {
}
