import { IHowdenParam } from '@app/core/models/howden-params';
import { IRequestContactInfoDto } from '@app/shared/models/api/teladoc/irequest-contact-info-dto';
import { IRequestDto } from '@app/shared/models/api/teladoc/request-dto';
import { IRequestFrontFilterDto } from '@app/shared/models/api/teladoc/request-front-filter-dto';
import { IRequestNewDto } from '@app/shared/models/api/teladoc/request-new-dto';
import { IRequestShortDto } from '@app/shared/models/api/teladoc/request-short-dto';
import { Observable } from 'rxjs';

export abstract class TeladocContract {
  /* #region GET */
  public abstract getFilteredRequestShort(filter: IRequestFrontFilterDto): Observable<Array<IRequestShortDto>>;
  public abstract getRequestShort(params: Array<IHowdenParam>): Observable<Array<IRequestShortDto>>;
  public abstract getRequestShort(params: Array<IHowdenParam>): Observable<Array<IRequestShortDto>>;
  public abstract getRequestContactInfo(requestId: number): Observable<Array<IRequestContactInfoDto>>;
  /* #endregion */
  /* #region POST */
  public abstract addRequest(request: IRequestDto): Observable<IRequestDto>;
  public abstract addManualRequest(request: IRequestNewDto): Observable<IRequestDto>;
  /* #endregion */
  /* #region PUT */
  public abstract resolveIncident(request: IRequestShortDto): Observable<IRequestShortDto>;
  public abstract reactivateRequestById(requestId: number): Observable<IRequestShortDto>;
  /* #endregion */
  /* #region DELETE */
  public abstract cancelRequestById(requestId: number): Observable<IRequestShortDto>;
  /* #endregion */
}
