import { Injectable } from '@angular/core';
import { IAppData } from '@app/core/models/app-data';
import { IRegionsDto } from '@app/features/teladoc-events/models/ui/regions-dto';
import { StorageManagerService } from '@coreServices/storage/storage-manager.service';
import { IRequestFrontFilterAppDto } from '@sharedModels/api/teladoc/request-front-filter-app-dto';

@Injectable({
  providedIn: 'root'
})
export class AppManagementService {
  private _appData: IAppData = {} as IAppData;

  constructor(private readonly storageSrv: StorageManagerService) {
    this.loadAppData();
  }

  private loadAppData(): void {
    this._appData = this.storageSrv.readAppdata();
  }

  public get appData(): IAppData {
    return this._appData;
  }

  private set appData(value: IAppData) {
    this._appData = value;
    this.updateAppDataOnStorage();
  }

  public get lastRequestFilter(): IRequestFrontFilterAppDto | undefined {
    return this._appData.lastRequestFilter;
  }

  public set lastRequestFilter(value: IRequestFrontFilterAppDto | undefined) {
    this._appData.lastRequestFilter = value;
    this.updateAppDataOnStorage();
  }

  public get avaliableRegions(): Array<IRegionsDto> | undefined {
    return this._appData.avaliableRegions;
  }

  public set avaliableRegions(value: Array<IRegionsDto> | undefined) {
    this._appData.avaliableRegions = value;
    this.updateAppDataOnStorage();
  }

  private updateAppDataOnStorage(): void {
    this.storageSrv.saveAppData(this._appData);
  }
}
