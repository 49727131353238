export enum RequestFiltersFastOptions {
  Ninguno = 0,
  Trimestre_Actual = 1,
  Trimestre_Pasado = 2,
  Mes_Pasado = 3,
  Mes_Actual = 4
}

export enum RequestFiltersStatusTypes {
  En_Circuito = 1,
  Con_Incidencia = 2,
  Completadas = 3,
  Borradas = 4
}

export enum ExceptionKindDB {
  None = -1,
  DatabaseNotAvailable = 0,
  Any = 1,
  NotFound = 2,
  Deleted = 3,
  AlreadyExists = 4
}

export enum Provider {
  Unknow = -1,
  TeladocSIM = 0,
  Teladoc = 1
}

export enum CompanyRequest {
  ALLIANZ = 33,
  SURNE = 95,
  ZURICH = 106,
  HELVETIA = 114,
  SANTALUCIA = 117
}

export enum CommunicationType {
  Unknow = 0,
  New = 1,
  Update = 2,
  Cancel = 3
}

export enum ReasonToDelete {
  Unknow = 0,
  Duplicate = 1,
  OwnProblem = 2,
  Other = 3
}

export enum ReasonChange {
  Unknow = 0,
  UserInteraction = 1,
  ProviderInteraction = 2,
  Demon = 3
}

export enum SSex {
  Unknow = 'N',
  Hombre = 'H',
  Mujer = 'M'
}

export enum TTimeSSlot {
  Unknow = 'U',
  Indiferent = 'I',
  Mornings = 'M',
  Afternoon = 'T',
}
