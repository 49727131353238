import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  RequestsFilterFormDialogComponent
} from '@features/teladoc-events/components/requests-filter-form-dialog/requests-filter-form-dialog.component';
import { IRequestFormFilterDialogResult } from '@shared/models/custom/request-form-filter-dialog-result';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowRequestsFilterFormDialogService {
  private dialogRef!: MatDialogRef<RequestsFilterFormDialogComponent>;

  constructor(private dialog: MatDialog) {
  }

  public open(): Observable<IRequestFormFilterDialogResult | undefined> {
    return new Observable(observer => {
      this.dialogRef = this.dialog.open(RequestsFilterFormDialogComponent, {
        width: 'auto'
      });
      this.dialogRef.afterClosed().subscribe((result: IRequestFormFilterDialogResult | undefined) => {
        observer.next(result);
        observer.complete();
      });
    });
  }
}
