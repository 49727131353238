<mat-dialog-content>
  <div class="flex f-fd--column f-gap--5px f-jc--start">
    <form [formGroup]="model.form"(keydown.enter)="$event.preventDefault()"style="overflow: auto">
      <div class="flex f-fd--column f-gap--20px f-jc--start">
        <div class="flex f-fd--column f-gap--20px f-jc--start">
          <!-- #region Dates (fast) -->
          <!-- <span>Selección rápida de fechas</span> -->
          <br />
          <howden-radiobutton-list
            [items]="model.dtFastOptions"
            [title]="'Selección rápida de fechas'"
            [formControlName]="model.C_DTFAST"
          ></howden-radiobutton-list>
          <!-- #endregion -->

          <div class="flex f-gap--5px f-ai--start f-ac--start"></div>
          <span>Fechas (preciso)</span>
          <div class="flex f-gap--5px f-ai--start f-ac--start">
            <!-- #region Register Date From -->
            <mat-form-field>
              <mat-label>Desde</mat-label>
              <input
                matInput
                [matDatepicker]="pickerFrom"
                [howdenControlError]="fromDateError"
                placeholder="dd/MM/aaaa"
                [formControlName]="model.C_DTREGISTERDATEFROM"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
              <mat-error #fromDateError></mat-error>
            </mat-form-field>
            <!-- #endregion -->

            <!-- #region Register Date Until -->
            <mat-form-field>
              <mat-label>Hasta</mat-label>
              <input
                matInput
                [matDatepicker]="pickerUntil"
                [howdenControlError]="untilDateError"
                placeholder="dd/MM/aaaa"
                [formControlName]="model.C_DTREGISTERDATEUNTIL"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerUntil"></mat-datepicker-toggle>
              <mat-datepicker #pickerUntil></mat-datepicker>
              <mat-error #untilDateError></mat-error>
            </mat-form-field>
            <!-- #endregion -->
          </div>
        </div>

        <!-- #region Tipo Peticiones -->
        <span>Tipo de Peticiones</span>
        <howden-checkbox-list
          [items]="model.stStatusOptions"
          title="Estados de la petición"
          [formControlName]="model.C_STTYPES"
        ></howden-checkbox-list>
        <!-- #endregion -->

        @if (model.officesOptions.length > 1) {
          <div>
            <span>Oficinas</span>
            <howden-checkbox-list
              [items]="model.officesOptions"
              title="Oficina de la petición"
              [formControlName]="model.C_OFFICES"
            ></howden-checkbox-list>
          </div>
        }

        <!-- #region Oficinas -->
        <!-- #endregion -->
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex f-gap--5px f-jc--end f-ai--center f-ac--center">
  <button mat-stroked-button color="warn" (click)="close(false)">
    <mat-icon class="red">cancel</mat-icon>
    Cancelar
  </button>
  <button mat-raised-button color="primary" (click)="close(true)">
    <mat-icon class="blue">task_alt</mat-icon>
    Aceptar
  </button>
  <button mat-raised-button (click)="clean()">
    <mat-icon>cleaning_services</mat-icon>
    <span>Limpiar</span>
  </button>
</mat-dialog-actions>
