import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ManualRequestEditDialogComponent
} from '@features/teladoc-events/components/manual-request-edit-dialog/manual-request-edit-dialog.component';
import { RequestViewModel } from '@features/teladoc-events/models/ui/request-view-model';
import { IManualRequestEditDialogResult } from '@shared/models/custom/manual-request-edit-dialog-result';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowManualRequestService {
  private dialogRef!: MatDialogRef<ManualRequestEditDialogComponent>;

  constructor(private dialog: MatDialog) {
  }

  public open(request: RequestViewModel | null): Observable<RequestViewModel | null> {
    return new Observable(observer => {
      this.dialogRef = this.dialog.open(ManualRequestEditDialogComponent, {
        disableClose: true,
        width: 'auto',
        data: {
          value: request
        } as IManualRequestEditDialogResult
      });
      this.dialogRef.afterClosed().subscribe((result: IManualRequestEditDialogResult) => {
        observer.next(result.ok ? result.value : null);
        observer.complete();
      });
    });
  }
}
