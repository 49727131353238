import { CommonModule, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiConfigurations } from '@environment/api-configurations';
import { environment } from '@environment/environment';
import {
  HowdenCoreModule,
  HowdenErrorPagesModule,
  HowdenPageTitleModule,
  HowdenWithoutMenuLayoutModule,
  IHowdenEnvironment,
  IPackageJsonWrapper,
  NotificationsService,
  getPaginatorIntl
} from '@howdeniberia/core-front';
import { MY_MAT_MOMENT_DATE_FORMATS } from '@shared/constants';
import packageJson from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TeladocEventsModule } from './features/teladoc-events/teladoc-events.module';
import { TeladocService } from './shared/services/api/teladoc.service';
import { TeladocContract } from './shared/services/api/teladocContract';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeEs, 'es');

export const AppProviders: Provider[] = [
  MatDatepickerModule,
  // MockService,
  NotificationsService,
  { provide: TeladocContract, useClass: TeladocService },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } },
  { provide: LOCALE_ID, useValue: 'es' },
  { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  { provide: MAT_DATE_FORMATS, useValue: MY_MAT_MOMENT_DATE_FORMATS },
  { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
];

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HowdenWithoutMenuLayoutModule,
        HowdenPageTitleModule,
        HowdenErrorPagesModule,
        SharedModule,
        TeladocEventsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        HowdenCoreModule.forRoot(environment as IHowdenEnvironment, packageJson as IPackageJsonWrapper, ApiConfigurations, true)], providers: [AppProviders, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
}
