export enum Sex {
  Unknow = 0,
  Man = 1,
  Woman = 2
}

export enum SexSpanish {
  Desconocido = 0,
  Hombre = 1,
  Mujer = 2
}

export enum SexSpanishVaLues {
  Desconocido = 'N',
  Hombre = 'H',
  Mujer = 'M'
}
