import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IRequestShortDto } from '@app/shared/models/api/teladoc/request-short-dto';
import { DateValidators, StringValidators } from '@howdeniberia/core-front';

import { RequestMapper } from './../../mappers/request-mapper';

export class EditRequestViewModel {
  private _form: FormGroup;
  private _initialValue: string;
  private _isReactivation: boolean;

  constructor() {
    this._form = this.createForm();
  }

  /* #region  Form Field Names */
  public readonly C_REQUESTID = 'requestId';
  public readonly C_PROYECTID = 'proyectId';
  public readonly C_CLIENTID = 'clientId';
  public readonly C_NAME = 'name';
  public readonly C_SURNAME1 = 'surname1';
  public readonly C_SURNAME2 = 'surname2';
  public readonly C_NIF = 'nif';
  public readonly C_LANDLINE = 'landLine';
  public readonly C_MOBILE = 'mobilePhone';
  public readonly C_SEX = 'sex';
  public readonly C_BRITH = 'birth';
  public readonly C_LANGUAGE = 'language';
  public readonly C_TIMESLOT = 'timeSlot';
  public readonly C_OBSERVATIONS = 'observations';
  /* #endregion */

  private createForm(): FormGroup {
    return new FormGroup({
      [this.C_REQUESTID]: new FormControl('', [Validators.required]),
      [this.C_PROYECTID]: new FormControl('', [Validators.required]),
      [this.C_CLIENTID]: new FormControl('', [Validators.required]),
      [this.C_NAME]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_SURNAME1]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_SURNAME2]: new FormControl('', [StringValidators.noSpacesEndStart]),
      [this.C_NIF]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_LANDLINE]: new FormControl('', []),
      [this.C_MOBILE]: new FormControl('', []),
      [this.C_SEX]: new FormControl('', [Validators.required]),
      [this.C_BRITH]: new FormControl('', [Validators.required, DateValidators.isPastDate(true)]),
      [this.C_LANGUAGE]: new FormControl('', [Validators.required]),
      [this.C_TIMESLOT]: new FormControl('', [Validators.required]),
      [this.C_OBSERVATIONS]: new FormControl('', [StringValidators.noSpacesEndStart])
    });
  }

  public loadFromRequestShortDto(data: IRequestShortDto, isReactivation: boolean): void {
    const map = new RequestMapper();
    this.proyectId = data.proyectId;
    this.clientId = data.clientId;
    this.requestId = data.requestId;
    this.name = data.name ?? '';
    this.surname1 = data.surname1 ?? '';
    this.surname2 = data.surname2 ?? '';
    this.nif = data.nif ?? '';
    this.birth = data.birth ? new Date(data.birth) : new Date();
    this.landLine = data.landLine;
    this.mobilePhone = data.mobilePhone;
    this.observations = data.observations;
    this.sex = map.getSexEnumTextFromString(data.sex);
    this.language = map.getLanguajeEnumTextFromString(data.language);
    this.timeSlot = map.getTimeSlotEnumTextFromString(data.timeSlot);
    this._initialValue = this.formVal;
    this._isReactivation = isReactivation;
  }

  /* #region  Properties */

  public get formChanged(): boolean {
    return this._initialValue !== this.formVal;
  }

  public get formVal(): string {
    return JSON.stringify(this._form.value);
  }

  public get form(): FormGroup {
    return this._form;
  }

  public get isReactivation(): boolean {
    return this._isReactivation;
  }

  /* #region From Model */
  public get requestId(): number {
    return this.form.get(this.C_REQUESTID)?.value;
  }

  public set requestId(value: number) {
    this.form.get(this.C_REQUESTID)?.setValue(value);
  }

  public get proyectId(): number {
    return this.form.get(this.C_PROYECTID)?.value;
  }

  public set proyectId(value: number) {
    this.form.get(this.C_PROYECTID)?.setValue(value);
  }

  public get clientId(): number {
    return this.form.get(this.C_CLIENTID)?.value;
  }

  public set clientId(value: number) {
    this.form.get(this.C_CLIENTID)?.setValue(value);
  }

  public get name(): string {
    return this.form.get(this.C_NAME)?.value;
  }

  public set name(value: string) {
    this.form.get(this.C_NAME)?.setValue(value);
  }

  public get surname1(): string {
    return this.form.get(this.C_SURNAME1)?.value;
  }

  public set surname1(value: string) {
    this.form.get(this.C_SURNAME1)?.setValue(value);
  }

  public get surname2(): string {
    return this.form.get(this.C_SURNAME2)?.value;
  }

  public set surname2(value: string) {
    this.form.get(this.C_SURNAME2)?.setValue(value);
  }

  public get nif(): string {
    return this.form.get(this.C_NIF)?.value;
  }

  public set nif(value: string) {
    this.form.get(this.C_NIF)?.setValue(value);
  }

  public get sex(): string {
    return this.form.get(this.C_SEX)?.value;
  }

  public set sex(value: string) {
    this.form.get(this.C_SEX)?.setValue(value);
  }

  public get birth(): Date {
    return this.form.get(this.C_BRITH)?.value;
  }

  public set birth(value: Date) {
    this.form.get(this.C_BRITH)?.setValue(value);
  }

  public get mobilePhone(): string {
    return this.form.get(this.C_MOBILE)?.value;
  }

  public set mobilePhone(value: string) {
    if (value) {
      this.form.get(this.C_MOBILE)?.setValue(value);
    }
  }

  public get landLine(): string {
    return this.form.get(this.C_LANDLINE)?.value;
  }

  public set landLine(value: string) {
    if (value) {
      this.form.get(this.C_LANDLINE)?.setValue(value);
    }
  }

  public get language(): string {
    return this.form.get(this.C_LANGUAGE)?.value;
  }

  public set language(value: string) {
    this.form.get(this.C_LANGUAGE)?.setValue(value);
  }

  public get timeSlot(): string {
    return this.form.get(this.C_TIMESLOT)?.value;
  }

  public set timeSlot(value: string) {
    this.form.get(this.C_TIMESLOT)?.setValue(value);
  }

  public get observations(): string {
    return this.form.get(this.C_OBSERVATIONS)?.value;
  }

  public set observations(value: string) {
    this.form.get(this.C_OBSERVATIONS)?.setValue(value);
  }
  /* #endregion */

  /* #endregion */
}
