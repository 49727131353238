<div class="table-container" [class.hidden]="!showTable">
  <mat-form-field>
    <mat-label>Puedes filtrar aquí</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="buscar (puedes encontrar por NIF o Num Solicitud; pero no busques fechas)"
      #generalFilter
    />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef># Sol.</th>
      <td mat-cell *matCellDef="let element">{{ element.requestId }}</td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Fecha Reg.</th>
      <td mat-cell *matCellDef="let element">
        {{ readDate(element.registerDate) | date : "yyyy/MM/dd" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="office">
      <th mat-header-cell *matHeaderCellDef>Oficina</th>
      <td mat-cell *matCellDef="let element">
        {{ element.officeName }} ({{ element.officeCode }})
      </td>
    </ng-container>
    <ng-container matColumnDef="policy">
      <th mat-header-cell *matHeaderCellDef>ID (Proyecto-Cli)</th>
      <td mat-cell *matCellDef="let element">{{ element.policy }}</td>
    </ng-container>
    <ng-container matColumnDef="companies">
      <th mat-header-cell *matHeaderCellDef>Comp.</th>
      <td mat-cell *matCellDef="let element">{{ element.companies }}</td>
    </ng-container>
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let element">{{ element.client }}</td>
    </ng-container>
    <ng-container matColumnDef="telephone">
      <th mat-header-cell *matHeaderCellDef>Tlf</th>
      <td mat-cell *matCellDef="let element">
        fijo: {{ element.landLine }} - móvil: {{ element.mobilePhone }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastInteraction">
      <th mat-header-cell *matHeaderCellDef>Actualizado</th>
      <td mat-cell *matCellDef="let element">
        {{ readDate(element.lastInteraction) | date : "yyyy/MM/dd HH:mm" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Reintentar</th>
      <td mat-cell *matCellDef="let element">
        @if (elementLoading !== element.requestId) {
          <div>
            @if (rowDone(element)) {
              <button mat-icon-button [disabled]="true">
                @if (element.done) {
                  <mat-icon matTooltip="Cuestionario Realizado" class="green cursor-not-allowed">how_to_reg</mat-icon>
                }
              </button>
            }
            @if (rowWaiting(element)) {
              <button mat-icon-button [disabled]="true">
                <mat-icon matTooltip="En Circuito" class="orange cursor-not-allowed">hourglass_bottom</mat-icon>
              </button>
            }
            @if (rowHasAction(element)) {
              <button mat-icon-button>
                <mat-icon matTooltip="Actualizar Petición" class="red" (click)="actionEditData(element)">manage_accounts</mat-icon>
              </button>
            }
          </div>
        }
        @if (elementLoading === element.requestId) {
          <div>
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="manualAction">
      <th mat-header-cell *matHeaderCellDef>Forzar</th>
      <td mat-cell *matCellDef="let element">
        @if (elementLoading !== element.requestId && seeManual && !rowDone(element) && !rowDelete(element)) {
          <div>
            <button mat-icon-button>
              <mat-icon matTooltip="Forzar Actualización" class="purple" (click)="actionForceEditData(element)">bolt</mat-icon>
            </button>
          </div>
        }
        @if (elementLoading === element.requestId) {
          <div>
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="info">
      <th mat-header-cell *matHeaderCellDef>Info</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="contactClick(element)">
          <mat-icon class="cursor-pointer blue">info</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef>Detalle</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-button
          [disabled]="element?.events.length === 0"
          (click)="eventClick(element)"
        >
          <mat-icon class="cursor-pointer">search</mat-icon>
          {{ element?.events.length }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Eliminar</th>
      <td mat-cell *matCellDef="let element">
        @if (elementLoading !== element.requestId) {
          <div>
            @if (seeDeleteAction(element)) {
              <button mat-icon-button>
                <mat-icon matTooltip="Eliminar" (click)="actionDelete(element)" class="red cursor-pointer">delete</mat-icon>
              </button>
            }
            @if (rowDelete(element)) {
              <mat-icon matTooltip="Petición Eliminada" class="red cursor-not-allowed">clear</mat-icon>
            }
          </div>
        }
        @if (elementLoading === element.requestId) {
          <div>
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
        }
      </td>
    </ng-container>
    <!-- #region múltiple -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="colsView.length">
        <div class="table-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
          <howden-request-events-list
            [dataSource]="dataSourceSecondary"
            class="width-max height-max"
            [colsView]="colsDetailView"
          ></howden-request-events-list>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="colsView"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    <!-- #endregion -->
    <tr
      mat-row
      *matRowDef="let element; columns: colsView"
      class="table-element-row, row-hover"
      [class.example-expanded-row]="expandedElement === element"
    ></tr>
  </table>
  <mat-paginator
    [class.hidden]="!showPaginator"
    [showFirstLastButtons]="true"
    [pageSizeOptions]="[10, 25, 50, 100]"
    [pageSize]="paginate"
  ></mat-paginator>
</div>
<div
  class="not-found-doc"
  style="text-align: center; color: red"
  [class.hidden]="loading || !noResults"
>
  <span>No hay resultados disponibles</span>
</div>
