import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from '@app/core/constants/general-constants';
import { HowdenControllerWithParams } from '@app/core/helpers/howden-controller-with-params';
import { IHowdenParam } from '@app/core/models/howden-params';
import { IRegionsDto } from '@app/features/teladoc-events/models/ui/regions-dto';
import { IRequestContactInfoDto } from '@app/shared/models/api/teladoc/irequest-contact-info-dto';
import { IRequestDto } from '@app/shared/models/api/teladoc/request-dto';
import { IRequestFrontFilterDto } from '@app/shared/models/api/teladoc/request-front-filter-dto';
import { IRequestNewDto } from '@app/shared/models/api/teladoc/request-new-dto';
import { IRequestShortDto } from '@app/shared/models/api/teladoc/request-short-dto';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { TeladocContract } from './teladocContract';

@Injectable({
  providedIn: 'root'
})
export class TeladocService implements TeladocContract {
  apiName = APP_CONSTANTS.API_TELADOC_NAME;
  basePath = 'api/teladoc';

  constructor(private httpClient: HowdenHttpClientService) {
  }

  /* #region  GET */

  /* #region Filtered */
  public getFilteredRequestShort(filter: IRequestFrontFilterDto): Observable<Array<IRequestShortDto>> {
    const path = `${this.basePath}/short/requests/filtered`;
    return this.httpClient.post<Array<IRequestShortDto>>(this.apiName, path, filter);
  }
  /* #endregion */

  /* #region Unfiltered */
  private getRequestShortByClientId(clientId: number): Observable<Array<IRequestShortDto>> {
    const path = `${this.basePath}/short/requests/client/${clientId}`;
    return this.getRequestsShort(path);
  }

  private getRequestShortByRequestId(requestId: number): Observable<Array<IRequestShortDto>> {
    const path = `${this.basePath}/short/request/${requestId}`;
    return this.getRequestsShort(path);
  }

  private getRequestShortByProjectId(proyectId: number): Observable<Array<IRequestShortDto>> {
    const path = `${this.basePath}/short/requests/proyect/${proyectId}`;
    return this.getRequestsShort(path);
  }

  public getRequestShort(params: Array<IHowdenParam>): Observable<Array<IRequestShortDto>> {
    if (params && params.length > 0) {
      let obj = <number>HowdenControllerWithParams.GetParamFromParamsValueByName('clientId', params);
      if (obj) {
        return this.getRequestShortByClientId(obj);
      }
      obj = <number>HowdenControllerWithParams.GetParamFromParamsValueByName('requestId', params);
      if (obj) {
        return this.getRequestShortByRequestId(obj);
      }
      obj = <number>HowdenControllerWithParams.GetParamFromParamsValueByName('proyectId', params);
      if (obj) {
        return this.getRequestShortByProjectId(obj);
      }
    }
    const path = `${this.basePath}/short/requests`;
    return this.getRequestsShort(path);
  }

  private getRequestsShort(path: string): Observable<Array<IRequestShortDto>> {
    return this.httpClient.get<Array<IRequestShortDto>>(this.apiName, path);
  }

  public getRequestContactInfo(requestId: number): Observable<Array<IRequestContactInfoDto>> {
    const path = `${this.basePath}/request/contact/readable/${requestId}`;
    return this.httpClient.get<Array<IRequestContactInfoDto>>(this.apiName, path);
  }
  /* #endregion */

  /* #region Offices */
  public getOffices(): Observable<Array<IRegionsDto>> {
    const path = `${this.basePath}/offices`;
    return this.httpClient.get<Array<IRegionsDto>>(this.apiName, path);
  }
  /* #endregion */
  /* #endregion */

  /* #region  POST */

  public addRequest(request: IRequestDto): Observable<IRequestDto> {
    return this.httpClient.post<IRequestDto>(this.apiName, this.basePath, request);
  }

  public addManualRequest(request: IRequestNewDto): Observable<IRequestDto> {
    return this.httpClient.post<IRequestDto>(this.apiName, `${this.basePath}/secure`, request);
  }
  /* #endregion */

  /* #region  PUT */

  public resolveIncident(request: IRequestShortDto): Observable<IRequestShortDto> {
    const path = `${this.basePath}/resolve`;
    return this.httpClient.put<IRequestShortDto>(this.apiName, path, request);
  }

  public reactivateRequestById(requestId: number): Observable<IRequestShortDto> {
    const path = `${this.basePath}/reactivate/${requestId}`;
    return this.httpClient.put<IRequestShortDto>(this.apiName, path, null);
  }
  /* #endregion */

  /* #region  DELETE */
  public cancelRequestById(requestId: number): Observable<IRequestShortDto> {
    const path = `${this.basePath}/${requestId}`;
    return this.httpClient.delete<IRequestShortDto>(this.apiName, path);
  }
  /* #endregion */
}
