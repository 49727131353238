import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  GENERAL_CONSTANTS,
  HowdenWithoutMenuLayoutComponent,
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  NotAuthorizedComponent
} from '@howdeniberia/core-front';

import { TeladocEventsModule } from './features/teladoc-events/teladoc-events.module';

export const AppRoutes = [
  {
    path: '',
    component: HowdenWithoutMenuLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => TeladocEventsModule
      }
    ],
  },
  {
    path: GENERAL_CONSTANTS.NotAuthorizedUrl,
    title: $localize`:@@app.route.notauthorized: No autorizado`,
    component: NotAuthorizedComponent
  },
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  { path: '**', redirectTo: '404' }
] as Routes;

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
