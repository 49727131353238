export enum State {
  Unknow = -3,
  Fallo_Proveedor = -2,
  Datos_Erroneos = -1,
  Esperando_Alta = 0,
  En_Circuito = 1,
  Cuestionario_Realizado = 2,
  Incidencia_Contactar_Cliente = 3,
  Incidencia_Modificar_Datos_Cliente = 4,
  Baja = 5,
  LOPD_Aceptada = 15,
  LOPD_En_Curso = 16,
  LOPD_Incidencia = 17,
  LODP_No_Localizado = 18,
}
