import { GENERAL_CONSTANTS, HowdenApiEnviroment, IHowdenApiInfo } from '@howdeniberia/core-front';
import { APP_CONSTANTS } from './../app/core/constants/general-constants';

export const ApiConfigurations: IHowdenApiInfo[] = [
  {
    name: GENERAL_CONSTANTS.ApiSecurityName,
    paths: {
      local: 'https://localhost:7249',
      develop: 'https://how-apisecurity.app-dev.howdeniberia.com',
      staging: 'https://how-apisecurity.app-staging.howdeniberia.com',
      production: 'https://how-apisecurity.app.howdeniberia.com',
      devHowdenGroup: 'https://how-apisecurity.app-dev.howdeniberia.com',
      preHowdenGroup: 'https://how-apisecurity.app-staging.howdeniberia.com',
      proHowdenGroup: 'https://how-apisecurity.app.howdeniberia.com'
    },
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
    needsToken: true
  },
  {
    name: APP_CONSTANTS.API_TELADOC_NAME,
    paths: {
      local: 'https://localhost:7249',
      develop: 'https://how-apitelesuscripcion.app-dev.howdeniberia.com',
      staging: 'https://how-apitelesuscripcion.app-staging.howdeniberia.com',
      production: 'https://how-apitelesuscripcion.app.howdeniberia.com',
      devHowdenGroup: 'https://how-apitelesuscripcion.app-dev.howdeniberia.com',
      preHowdenGroup: 'https://how-apitelesuscripcion.app-staging.howdeniberia.com',
      proHowdenGroup: 'https://how-apitelesuscripcion.app.howdeniberia.com'
    },
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
    needsToken: true
  }
] as IHowdenApiInfo[];
