<div class="flex f-fd--column f-gap--30px f-jc--start f-ai--center f-ac--center">
  <div class="text-center" style="font-size: medium">
    <span>Le recordamos que el teléfono de contacto con TELADOC es el
      <strong><a href="{{ 'tel:' + teladocPhone }}">{{ teladocPhoneStr }}</a></strong>
    </span>
  </div>
</div>
@if (searchAll) {
  <div id="action-buttons" class="flex f-gap--10px">
    <button mat-fab color="success" (click)="reload()">
      <mat-icon>sync</mat-icon>
    </button>
    @if (!filtered) {
      <button mat-fab color="warn" (click)="showFilter()">
        <mat-icon>filter_list</mat-icon>
      </button>
    }
    @if (filtered) {
      <button mat-fab color="info" (click)="showFilter()">
        <mat-icon>filter_alt_off</mat-icon>
      </button>
    }
    <button
      mat-fab
      color="info"
      [howdenRolEvalHide]="[allowedDirectRequest]"
      (click)="goToRequest()"
      matTooltip="Directa a una solicitud"
    >
      <mat-icon>outbound</mat-icon>
    </button>
  </div>
}
<div>
  <howden-requests-list [data]="data"></howden-requests-list>
</div>
