import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HowdenControllerWithParams } from '@app/core/helpers/howden-controller-with-params';
import { AppManagementService } from '@app/core/services/app/app-management.service';
import { IRequestShortDto } from '@app/shared/models/api/teladoc/request-short-dto';
import { TeladocService } from '@app/shared/services/api/teladoc.service';
import { RequestsListComponent } from '@features/teladoc-events/components/requests-list/requests-list.component';
import { RequestMapper } from '@features/teladoc-events/mappers/request-mapper';
import { ShowManualRequestService } from '@features/teladoc-events/services/models/show-manual-request.service';
import { ShowRequestsFilterFormDialogService } from '@features/teladoc-events/services/models/show-requests-filter-form-dialog.service';
import { DialogResultTypes, HowdenAlertService, HowdenAskForDataService, HowdenLoaderService, IHowdenAskForData } from '@howdeniberia/core-front';
import { TelesuscripcionAccessRoles } from '@shared/models/api/teladoc/telesuscripcion-access-roles';
import { IRequestFrontFilterAppDto } from '@sharedModels/api/teladoc/request-front-filter-app-dto';
import { IRequestFormFilterDialogResult } from '@sharedModels/custom/request-form-filter-dialog-result';
import { IRegionsDto } from '../../models/ui/regions-dto';

@Component({
  selector: 'howden-teladoc-events-page',
  templateUrl: './teladoc-events-page.component.html',
  styleUrls: ['./teladoc-events-page.component.scss']
})
export class TeladocEventsPageComponent extends HowdenControllerWithParams implements OnInit, AfterViewInit {
  teladocPhone = 932532183;
  teladocPhoneStr = '932 532 183';
  data: Array<IRequestShortDto> = [];
  dataSource: MatTableDataSource<IRequestShortDto>;
  version = '';
  filtered = false;
  filterApp: IRequestFrontFilterAppDto | undefined = undefined;
  mapper: RequestMapper = new RequestMapper();
  searchAll = false;
  allowedDirectRequest = TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC;
  isManual = false;
  @ViewChild(RequestsListComponent) table!: RequestsListComponent;

  constructor(
    protected override activatedRoute: ActivatedRoute,
    private readonly appSrv: AppManagementService,
    private readonly teladocSrv: TeladocService,
    private readonly filtersSrv: ShowRequestsFilterFormDialogService,
    private readonly alertSrv: HowdenAlertService,
    private readonly loaderSrv: HowdenLoaderService,
    private readonly askFordataSrv: HowdenAskForDataService,
    private showManualSrv: ShowManualRequestService,
    private readonly router: Router
  ) {
    super(activatedRoute);
  }

  ngAfterViewInit(): void {
    if (!this.isManual) {
      this.readOffices();
    }
  }

  ngOnInit(): void {
    this.readParams();
  }

  readOffices(): void {
    this.loaderSrv.showSpinner();
    // read offices and update to app data
    this.teladocSrv.getOffices().subscribe({
      next: (data: Array<IRegionsDto>) => {
        this.appSrv.avaliableRegions = data;
        this.loaderSrv.hideSpinner();
      },
      error: (err) => {
        this.alertSrv.error('API no disponible', '', true, true);
        this.loaderSrv.hideSpinner();
      }
    });
  }

  /* #region Params */
  readParams() {
    this.isManual = this.activatedRoute.snapshot.url[0].path.toLocaleLowerCase() === 'manual';
    this.searchAll = this.activatedRoute.snapshot.routeConfig?.path === 'requests';
    if (this.isManual) {
      this.activatedRoute.queryParamMap.subscribe(data => {
        this.processManualRequest(data);
      });
    } else if (!this.searchAll) {
      this.getRequests();
    }
  }
  /* #endregion */

  /* #region Manual */
  processManualRequest(data: ParamMap): void {
    const mapper: RequestMapper = new RequestMapper();
    this.showManualSrv.open(mapper.queryParamsToRequestViewModel(data)).subscribe(result => {
      if (result) {
        // generate new manual request
        this.loaderSrv.showSpinner();
        this.teladocSrv.addManualRequest(mapper.requestViewModelToRequestNewDto(result)).subscribe({
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          next: (requestNew) => {
            this.getRequests();
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          error: (err) => {
            const msg = err ? err.error?.title : null;
            this.alertSrv.error(`Se ha producido un error ${msg ? `: ${msg}` : ''}`, '', true);
            this.loaderSrv.hideSpinner();
          },
        });
      }
    });
  }
  /* #endregion */

  /* #region Get Requests */
  getRequests(): void {
    this.teladocSrv.getRequestShort(this.params).subscribe({
      next: (result) => {
        this.data = result;
      },
      error: (err) => {
        this.alertSrv.error('API no disponible', '', true, true);
        this.loaderSrv.hideSpinner();
      }
    });
  }

  getRequestsFiltered(): void {
    this.loaderSrv.showSpinner();
    const filterValues = this.mapper.iRequestFrontFilterAppDtoToIRequestFrontFilterDto(this.filterApp);
    this.teladocSrv.getFilteredRequestShort(filterValues).subscribe({
      next: (result) => {
        this.data = result;
        // this.data = result.filter(x => x.done === true || x.deleted === true);
        // this.table.loadData(this.data, 5);
        this.loaderSrv.hideSpinner();
      },
      error: (err) => {
        this.alertSrv.error('APi not avaliable', err.message, true, true);
        this.loaderSrv.hideSpinner();
      }
    });
  }
  /* #endregion */

  /* #region Buttons */
  reload(): void {
    this.getRequestsFiltered();
  }

  showFilter(): void {
    this.filtersSrv.open().subscribe({
      next: (result: IRequestFormFilterDialogResult | undefined) => {
        this.filtered = result && result.ok ? true : false;
        this.filterApp = result && result.ok ? this.mapper.requestFilterFormViewModelToIRequestFrontFilterAppDto(result.value) : undefined;
        if (result && result.ok) {
          this.appSrv.lastRequestFilter = this.filterApp;
        }
      }
    });
  }

  goToRequest(): void {
    const options = {
      title: 'Pon un número de solicitud',
      allowCancel: true
    } as IHowdenAskForData;

    this.askFordataSrv.open(options).subscribe(userResponse => {
      if (userResponse && userResponse.result === DialogResultTypes.OK && !isNaN(Number(userResponse.resultData))) {
        const requestId = +`${userResponse.resultData}`;
        this.router.navigateByUrl(`/request/${requestId}`);
      }
    });
  }
  /* #endregion */
}
