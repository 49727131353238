import { Component } from '@angular/core';
import { GENERAL } from '@shared/constants';

@Component({
  selector: 'howden-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = GENERAL.AppName;
}
