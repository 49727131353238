export class TelesuscripcionAccessRoles {
  // Supervisor
  public static TELESUSCRIPCIONSUPERVISOR = 'TelesuscripcionSupervisor';
  // Gestor
  public static TELESUSCRIPCIONGESTOR = 'TelesuscripcionGestor';
  // BO
  public static TELESUSCRIPCIONBO = 'TelesuscripcionBO';
  // DT
  public static TELESUSCRIPCIONDT_NORTE = 'TelesuscripcionDT_Norte';
  public static TELESUSCRIPCIONDT_LEVANTE = 'TelesuscripcionDT_Levante';
  public static TELESUSCRIPCIONDT_CATALUÑA = 'TelesuscripcionDT_Cataluña';
  public static TELESUSCRIPCIONDT_CENTROSUR = 'TelesuscripcionDT_CentroSur';
  public static TELESUSCRIPCIONDT_CAC = 'TelesuscripcionDT_CAC';
  // TECH
  public static TELESUSCRIPCIONTECNIC = 'TelesuscripcionTecnic';
  // ADMIN ?
  public static TELESUSCRIPCIONADMIN = 'TelesuscripcionAdmin';
}
