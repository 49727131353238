export enum Languaje {
  Unknow = 0,
  Castellano = 1,
  Ingles = 2,
  Aleman = 3,
  Frances = 4,
  Portugues = 5,
  Italiano = 6,
  Chino = 7,
  Catalan = 8
}

export enum LanguajeValues {

  Unknow = 'UNK',
  Castellano = 'CS1',
  Ingles = 'EN1',
  Aleman = 'DE1',
  Frances = 'FR1',
  Portugues = 'PT2',
  Italiano = 'IT1',
  Chino = 'ZH1',
  Catalan = 'CT1',
}
