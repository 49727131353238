export const REQUESTS_LIST_COLUMNS = [
  'id',
  'date',
  'office',
  'companies',
  'client',
  'telephone',
  'lastInteraction',
  'policy',
  'manualAction',
  'action',
  'view',
  'info',
  'delete'
];
