import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeSlotSpanish } from '@app/shared/enums';
import { Languaje } from '@app/shared/enums/languajes';
import { IRequestClientDataDialogResult } from '@app/shared/models/custom/request-client-data-dialog-result';
import { RequestMapper } from '@features/teladoc-events/mappers/request-mapper';
import { EditRequestViewModel } from '@features/teladoc-events/models/ui/edit-request-view-model';
import {
  DialogResultTypes,
  HowdenAskForSelectService,
  IAskForSelectData,
  IDialogResult,
  IHowdenItem
} from '@howdeniberia/core-front';
import { SexSpanish } from '@shared/enums/sex';

@Component({
  selector: 'howden-request-client-data-edit-dialog',
  templateUrl: './request-client-data-edit-dialog.component.html',
  styleUrls: ['./request-client-data-edit-dialog.component.scss']
})
export class RequestClientDataEditDialogComponent implements OnInit {
  model: EditRequestViewModel = new EditRequestViewModel();

  constructor(
    public dialogRef: MatDialogRef<RequestClientDataEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: IRequestClientDataDialogResult,
    private askForSelectSrv: HowdenAskForSelectService) {
  }

  ngOnInit(): void {
    this.loadForm();
  }

  loadForm(): void {
    const obj = this.data.request;
    const isReactivation = this.data.isReactivation;
    this.model.loadFromRequestShortDto(obj, isReactivation);
  }

  public get numbersOK(): boolean {
    const land = this.model.form.get(this.model.C_LANDLINE)?.value;
    const mob = this.model.form.get(this.model.C_MOBILE)?.value;
    const res = new RequestMapper().validateContactNumbers(
      land?.toString(),
      mob?.toString()
    );
    return res;
  }

  /* #region Select Options */
  selectLanguage(): void {
    const options = {
      title: 'Seleccione Idioma',
      label: 'Idioma',
      allowCancel: true,
      items: this.getLanguajeOptions()
    } as IAskForSelectData;

    this.askForSelectSrv
      .open(options)
      .subscribe((result: IDialogResult<IHowdenItem>) => {
        const currentVal = this.model.language;
        if (result.result === DialogResultTypes.OK) {
          const val = result.resultData?.description;
          this.model.language = val ? val : currentVal;
        }
      });
  }

  private getLanguajeOptions(): IHowdenItem[] {
    const result: IHowdenItem[] = [];
    try {
      for (const key in Languaje) {
        if (!isNaN(Number(key))) {
          result.push({ key: Number(key), description: Languaje[key] } as IHowdenItem);
        }
      }
    } catch (ex) {
      // Error ignored
    }
    return result;
  }

  selectTimeSlot(): void {
    const options = {
      title: 'Seleccione Franja Horaria',
      label: 'Franja',
      allowCancel: true,
      items: this.getTimeSlotOptions()
    } as IAskForSelectData;

    this.askForSelectSrv
      .open(options)
      .subscribe((result: IDialogResult<IHowdenItem>) => {
        const currentVal = this.model.timeSlot;
        if (result.result === DialogResultTypes.OK) {
          const val = result.resultData?.description;
          this.model.timeSlot = val ? val : currentVal;
        }
      });
  }

  private getTimeSlotOptions(): IHowdenItem[] {
    const result: IHowdenItem[] = [];
    try {
      for (const key in TimeSlotSpanish) {
        if (!isNaN(Number(key))) {
          result.push({
            key: Number(key),
            description: TimeSlotSpanish[key]
          } as IHowdenItem);
        }
      }
    } catch (ex) {
      // Error ignored
    }
    return result;
  }

  selectSex(): void {
    const options = {
      title: 'Seleccione Sexo',
      label: 'Sexo',
      allowCancel: true,
      items: this.getSexOptions()
    } as IAskForSelectData;

    this.askForSelectSrv
      .open(options)
      .subscribe((result: IDialogResult<IHowdenItem>) => {
        const currentVal = this.model.sex;
        if (result.result === DialogResultTypes.OK) {
          const val = result.resultData?.description;
          this.model.sex = val ? val : currentVal;
        }
      });
  }

  private getSexOptions(): IHowdenItem[] {
    const result: IHowdenItem[] = [];
    try {
      for (const key in SexSpanish) {
        if (!isNaN(Number(key))) {
          result.push({
            key: Number(key),
            description: SexSpanish[key]
          } as IHowdenItem);
        }
      }
    } catch (ex) {
      // Error ignored
    }
    return result;
  }
  /* #endregion */

  clean(): void {
    this.model.form.reset();
  }

  close(save: boolean): void {
    if (save) {
      this.cleanResult();
      this.data.request = new RequestMapper().editRequestViewModelToIRequestShortDto(this.model);
    }
    this.data.ok = save;
    this.dialogRef.close(this.data);
  }

  cleanResult(): void {
    this.model.nif = this.model.nif.trim().replace('-', '').replace(' ', '');
  }

  showError(): void {
    // show error
  }
}
