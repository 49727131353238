import { FormControl, FormGroup } from '@angular/forms';
import { RequestFiltersFastOptions, RequestFiltersStatusTypes } from '@app/shared/enums/common-enum';
import { IHowdenItem } from '@howdeniberia/core-front';

export class RequestFilterFormViewModel {
  private _form: FormGroup;
  private _initialValue: string;

  constructor() {
    this._form = this.createForm();
  }

  public setInitialFormValue(): void {
    this._initialValue = this.formVal;
  }

  /* #region  Form Field Names */
  /* #region Dates */
  /* #region Fast */
  public readonly C_DTFAST = 'dtFast';

  dtFastOptions = [
    { key: RequestFiltersFastOptions.Ninguno, description: RequestFiltersFastOptions[RequestFiltersFastOptions.Ninguno], selected: false } as IHowdenItem,
    { key: RequestFiltersFastOptions.Trimestre_Actual, description: RequestFiltersFastOptions[RequestFiltersFastOptions.Trimestre_Actual], selected: false } as IHowdenItem,
    { key: RequestFiltersFastOptions.Trimestre_Pasado, description: RequestFiltersFastOptions[RequestFiltersFastOptions.Trimestre_Pasado], selected: false } as IHowdenItem,
    { key: RequestFiltersFastOptions.Mes_Pasado, description: RequestFiltersFastOptions[RequestFiltersFastOptions.Mes_Pasado], selected: false } as IHowdenItem,
    { key: RequestFiltersFastOptions.Mes_Actual, description: RequestFiltersFastOptions[RequestFiltersFastOptions.Mes_Actual], selected: false } as IHowdenItem
  ];

  /* #endregion */
  /* #region Precise */
  public readonly C_DTREGISTERDATEUNTIL = 'dtRegisterDateUntil';
  public readonly C_DTREGISTERDATEFROM = 'dtRegisterDateFrom';
  /* #endregion */
  /* #endregion */
  /* #region Request Status */
  public readonly C_STTYPES = 'stTypes';
  stStatusOptions = [
    { key: RequestFiltersStatusTypes.Borradas, description: RequestFiltersStatusTypes[RequestFiltersStatusTypes.Borradas] } as IHowdenItem,
    { key: RequestFiltersStatusTypes.Completadas, description: RequestFiltersStatusTypes[RequestFiltersStatusTypes.Completadas] } as IHowdenItem,
    { key: RequestFiltersStatusTypes.Con_Incidencia, description: RequestFiltersStatusTypes[RequestFiltersStatusTypes.Con_Incidencia] } as IHowdenItem,
    { key: RequestFiltersStatusTypes.En_Circuito, description: RequestFiltersStatusTypes[RequestFiltersStatusTypes.En_Circuito] } as IHowdenItem
  ];
  /* #endregion */
  /* #region Offices */
  public readonly C_OFFICES = 'officeCodes';
  officesOptions: Array<IHowdenItem> = [
    // { key: RequestFiltersStatusTypes.Borradas, description: RequestFiltersStatusTypes[RequestFiltersStatusTypes.Borradas] } as IHowdenItem,
    // { key: RequestFiltersStatusTypes.Completadas, description: RequestFiltersStatusTypes[RequestFiltersStatusTypes.Completadas] } as IHowdenItem,
    // { key: RequestFiltersStatusTypes.Con_Incidencia, description: RequestFiltersStatusTypes[RequestFiltersStatusTypes.Con_Incidencia] } as IHowdenItem,
    // { key: RequestFiltersStatusTypes.En_Circuito, description: RequestFiltersStatusTypes[RequestFiltersStatusTypes.En_Circuito] } as IHowdenItem,
  ];
  /* #endregion */
  /* #endregion */

  private createForm(): FormGroup {
    return new FormGroup({
      [this.C_DTFAST]: new FormControl(null, []),
      [this.C_STTYPES]: new FormControl(null, []),
      [this.C_DTREGISTERDATEUNTIL]: new FormControl('', []),
      [this.C_DTREGISTERDATEFROM]: new FormControl('', []),
      [this.C_OFFICES]: new FormControl('', [])
    });
  }

  /* #region  Properties */

  public get anyValueSet(): boolean {
    const fast = this.dtFast && this.dtFast !== RequestFiltersFastOptions.Ninguno ? true : false;
    const dates = !!this.dtRegisterDateFrom || !!this.dtRegisterDateUntil;
    const types = this.stTYPES && this.stTYPES.length > 0 ? true : false;
    const offices = this.officeCodes && this.officeCodes.length > 0 ? true : false;
    return fast || dates || types || offices;
  }

  public get formChanged(): boolean {
    return this._initialValue !== this.formVal;
  }

  public get formVal(): string {
    return JSON.stringify(this._form.value);
  }

  public get form(): FormGroup {
    return this._form;
  }

  public resetForm(): void {
    this.dtFastOptions.forEach(x => x.selected = false);
    this.form.reset();
  }

  /* #region From Form */
  public get dtRegisterDateUntil(): string | null {
    return this.form.get(this.C_DTREGISTERDATEUNTIL)?.value;
  }

  public set dtRegisterDateUntil(value: string | null) {
    this.form.get(this.C_DTREGISTERDATEUNTIL)?.setValue(value);
  }

  public get dtRegisterDateFrom(): string | null {
    return this.form.get(this.C_DTREGISTERDATEFROM)?.value;
  }

  public set dtRegisterDateFrom(value: string | null) {
    this.form.get(this.C_DTREGISTERDATEFROM)?.setValue(value);
  }

  public get dtFast(): number | null {
    return this.form.get(this.C_DTFAST)?.value;
  }

  public set dtFast(value: number | null) {
    this.form.get(this.C_DTFAST)?.setValue(value);
  }

  public get stTYPES(): Array<number> | null {
    return this.form.get(this.C_STTYPES)?.value;
  }

  public set stTYPES(value: Array<number> | null) {
    this.form.get(this.C_STTYPES)?.setValue(value);
  }

  public get officeCodes(): Array<string> | null {
    return this.form.get(this.C_OFFICES)?.value;
  }

  public set officeCodes(value: Array<string> | null) {
    this.form.get(this.C_OFFICES)?.setValue(value);
  }
  /* #endregion */

  /* #endregion */
}
